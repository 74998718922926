export default {
    getUrlKey: function (name) {
        let query = (decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [""])[1]));       
        return (query==null || query == '')?null:query.replace(/\+/g,'%20');
    },
    getParmeter:function(variable) {
        let query = window.location.href.split('?')[1]; // 这里query的结果是：   promotion_code=ZB06AKXFSR&sku=100
        let vars = query.split('&'); // 这里vars的结果是：   ["promotion_code=ZB06AKXFSR", "sku=100"]
        for (let i = 0; i < vars.length; i++) { //然后循环
            let pair = vars[i].split('='); //  循环第一次结果pair ["promotion_code", "ZB06AKXFSR"]  循环第二次结果pair ["sku", "100"]
            if (pair[0] === variable) { // 做判断 如果参数名和我们的实参一样
                return pair[1]; // 就返回对应的值
            }
        }
        return '';
    },
    getUrlParam:function(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
        var r = window.location.search.substr(1).match(reg); //匹配目标参数
        if(r != null) return unescape(r[2]);
        return null; //返回参数值
    }
}