import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

// 公共路由
export const constantRoutes = [
  {
    path: '/',
    component: () => import('@/view/index'),
    /*
    redirect: 'index',
    children: [
      {
        path: 'index',
        component: () => import('@/view/page2'),
        name: 'Index',
        meta: { title: '亿力停车',affix: true }
      }
    ]  */ 
  },
  {
    path: '/bak',
    name:'bak',
    component: () => import('@/view/indexbak'),
    /*
    redirect: 'index',
    children: [
      {
        path: 'index',
        component: () => import('@/view/page2'),
        name: 'Index',
        meta: { title: '亿力停车',affix: true }
      }
    ]  */ 
  },
  {
    path: '/costInfo',
    name: 'costInfo',
    component: () => import('@/view/costInfo'),
  },
  {
    path: '/coupon',
    name: 'coupon',
    component: () => import('@/view/coupon'),
  },
  {
    path: '/couponInfo',
    name: 'couponInfo',
    component: () => import('@/view/couponInfo'),
  },
  {
    path: '/orderDetail',
    component: () => import('@/view/orderDetail'),
  },
  {
    path: '/pay',
    component: () => import('@/view/pay'),
  },
  {
    path: '/success',
    component: () => import('@/view/success'),
  },
  {
    path:'/phoneValidate',
    component: () => import('@/view/phoneValidate'),
  },
  {
    path: '/constdetail',
    component: () => import('@/view/costdetail'),
  },
  {
    path: '/constdetailjs',
    component: () => import('@/view/costdetailjs'),
  },
  {
    path: '/couponorcar',
    name: 'couponorcar',
    component: () => import('@/view/couponorcar'),
  },
  // {
  //   path: '',
  //   component: Layout,
  //   redirect: 'index',
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/index'),
  //       name: 'Index',
  //       meta: { title: '首页', icon: 'dashboard', affix: true }
  //     }
  //   ]
  // },
]

// 动态路由，基于用户权限动态去加载


// 防止连续点击多次路由报错
let routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

export default new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
