import Vue from 'vue'
import Vant from "vant";
import "vant/lib/index.css";
import App from './App.vue'
import Element from 'element-ui'
import router from './router'
import 'element-ui/lib/theme-chalk/index.css'
import utils from './utils/utils.js'
Vue.prototype.$utils = utils
//Vue.prototype.$router=router
Vue.config.productionTip = false
Vue.use(Vant);
Vue.use(Element, {
  size: 'medium' // set element-ui default size
})
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
